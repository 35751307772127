

.loading-container {
    width: 100vw;
    height: 100vh;
    background-color: #9c27b0;

}


@keyframes example {
    from {
        width:0;
        height:0;
    }
    to {
        width: 100vw;
        height: 200vh;
    }
}

.loading-component {
    background-color: white;
    /* center this div */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* make it a circle */
    border-radius: 50%;
    animation-name: example;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
}



