
.donation-type-container {
    width: 50vw;
    height: 50vh;
    /* background-color: greenyellow; */
    display: flex;
    flex-direction: row;
    /* gap: 3rem; */
    justify-content: center;
    align-items: center;
    /* background-color: red; */
    /* padding-bottom: 2rem; */
    /* background-color: blue; */
    margin-top: -2rem;
}

.donate-page-container {
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    /* gap: 3rem; */
    align-items: center;
    justify-content: space-evenly;
    /* padding: 0 30%; */
    height: 100vh;
    /* overflow: hidden; */

}

.donate-page-cards-container {
    /* background-color: rebeccapurple; */
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: space-evenly;
    /* padding: 0 10%; */
    /* margin-bottom: 3rem; */
    /* padding-bottom: 10%; */
    /* background-color: red; */

}

.fast-giver-container {
    background-color: rgb(156, 39, 176, 0.5);
    height: 300px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 2rem;
}

.fast-giver-container:hover {
    background-color: rgb(156, 39, 176, 0.8);
}

.engangs-donasjon-container {
    background-color: rgb(156, 39, 176, 0.5);
    height: 300px;
    width: 300px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    border-radius: 2rem;
}

.engangs-donasjon-container:hover {
    background-color: rgb(156, 39, 176, 0.8);
}


.donation-type-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}


.paypal-buttons {
    /* padding-bottom:10% */
    /* background-color: blue; */
    height: 50vh !important;

}


/* make it responsive for mobile phones */
@media screen and (max-width: 812px) {
   
    .donation-type-container {
        width: 50vw;
        height: 50vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: -2rem;
    }
    
    .donate-page-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 100vh;
    
    }
    
    .donate-page-cards-container {
        position: relative;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        align-items: center;
        justify-content: space-evenly;
    
    }
    
    .fast-giver-container {
        background-color: rgb(156, 39, 176, 0.5);
        height: 250px;
        width: 250px    ;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 2rem;
    }
    
    .fast-giver-container:hover {
        background-color: rgb(156, 39, 176, 0.8);
    }
    
    .engangs-donasjon-container {
        background-color: rgb(156, 39, 176, 0.5);
        height: 250px;
        width: 250px;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
        border-radius: 2rem;
    }
    
    .engangs-donasjon-container:hover {
        background-color: rgb(156, 39, 176, 0.8);
    }
    
    
    .donation-type-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }
    
    
    .paypal-buttons {
        height: 50vh !important;
    
    }
    
}


@media screen and (max-width: 450px) {

    .donate-page-cards-container {
        gap: 0.5rem;
    }

    .donate-page-container {
        height: 70vh;

    }

    .engangs-donasjon-container {
        width:150px;
        height: 150px;
    }

    .fast-giver-container {
        width:150px;
        height: 150px;
    }

    .engangs-donasjon-container h1 {
        font-size: 1.3rem;
    }

    .fast-giver-container h1 {
        font-size: 1.3rem;
    }
}