

.header-container {
    /* background-color: red; */
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
    /* justify-content: space-around; */
}

.header-container p {
    /* font-weight: bold; */
    font-size: 1.2rem;
}

.header-background-image{
    background-position: center;
    /* background-repeat: no-repeat; */
    /* position: relative; */
    background-size: cover;
    height: 80vh;
    width: 100%;
    /* aspect-ratio: 1/1; */
    object-fit: cover;
}

.donate-link {
    /* remove link underline */
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.donate-container {
    text-align: center;
}

/* Make it responsive for small screens */
@media screen and (max-width: 768px) {
    .header-container {
        /* background-color: antiquewhite; */
        height: 70vh;
        display: flex;
        flex-direction: column;
        /* justify-content: space-around; */
        gap: 1rem;
        align-items: center;
        justify-content: space-evenly;
    }
    .header-container h1 {
        font-size: 1.5rem;
    }

    .donate-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: space-evenly;
        /* background-color: rebeccapurple; */
        /* padding-right: 40vw; */
    }
    
    .donate-link {
        /* remove link underline */
        text-decoration: none;
        /* display: flex; */
        /* justify-content: center; */
        /* align-items: center; */
    }

}

/* make it responsive for mobile phones in landskape mode */
@media screen and (max-height: 360px) {
    .header-container {
        /* background-color: antiquewhite; */
        height: 100vh;
        display: flex;
        flex-direction: column;
        padding-top: 15vh;
        /* justify-content: space-around; */
        gap: 1rem;
        align-items: center;
        justify-content: space-evenly;
    }
    .header-container h1 {
        font-size: 1.5rem;
    }

    .donate-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: space-evenly;
        padding-bottom: 35vh;
    }
    
    .donate-link {
        /* remove link underline */
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

}