



.gallery-container {
    width: 100vw;
    height: 100%;
    /* background-color: #a84848; */
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.gallery-content_1 {
    width:100vw;
    height:100%;
    /* background-color: blue; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */

}

.gallery-text_1 {
    width: 40%;
    /* increase space between lines */
    line-height: 2;
}


.gallery-image_1 {
    width: 50%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
}

.gallery-image_1 img{
    object-fit: cover;
    height: auto;
    width: 60%;
}



/* Make it responsive for mobile phones */
@media screen and (max-width: 850px) {
    .gallery-container {
        width: 100vw;
        height: 100%;
        /* background-color: #a84848; */
        display: flex;
        flex-direction: column;
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    
    .gallery-content_1 {
        width:100vw;
        height:100%;
        /* background-color: blue; */
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: space-between; */
    
    }
    
    .gallery-text_1 {
        width: 85%;
        /* increase space between lines */
        line-height: 2;
    }
    
    
    .gallery-image_1 {
        width: 95%;
        /* background-color: red; */
        display: flex;
        justify-content: center;
    }
    
    .gallery-image_1 img{
        object-fit: cover;
        height: auto;
        width: 100%;
    }
    

}