


.about-container {
    background-color: #f5f5f5;
    width: 80%;
    margin: 0 auto;
    padding: 5% 0%;
    margin-top: -4%;
    overflow: hidden;
}

.about-container h1 {
    font-size: 2rem;
    text-align: center;
    /* padding: 2rem 0; */
    justify-content: center;
}

.about-container p {
    width: 100%;
    /* justify-content: center; */
    text-align: left;
    gap: 2rem;
    padding: 0 20%;
    font-size: large;
}

/* make it responsive for mobile phones */
@media screen and (max-width: 450px) {
    .about-container {
        margin-top: -12%;
        width: 100vw;
    }
    .about-container h1 {

    }
    .about-container p {
        text-align: left;
        /* increase space between lines */
        line-height: 1.8;
      
    }
}