

.our-support-container {
    background-color: antiquewhite;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    /* justify-content: space-around; */
}

.our-support-container h1 {
    font-size: 2rem;
    text-align: center;
    /* padding: 2rem 0; */
    justify-content: center;
}