


.social-media-posts-container {
    background-color: antiquewhite;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 1rem;
    padding: 0 10%; 
}


.post-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 2rem;
}


/* make it responsive for small sccreens and mobiles */
@media screen and (max-width: 768px) {
    .social-media-posts-container {
        background-color: antiquewhite;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        gap: 1rem;
        padding: 0 10%; 
    }
    .post-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 2rem;
    }
}