

.donation-cards-container {
    width: 20vw;
    height: 40vh;
    background-color: blueviolet;
    clip-path: ellipse(25% 42% at 50% 50%);
    /* text-align: center; */
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
       
}


.donation-cards-container h1 {
    font-size: 2rem;
    color: white;
    /* justify-content: center; */
    /* padding: 1rem 0; */
    /* background-color: rebeccapurple; */
}

/* make the cards responsive for small screens */
@media screen and (max-width: 768px) {
    .donation-cards-container {
        height: 90px;
        width: 90px;
        background-color: blueviolet;
        /* clip-path: ellipse(25% 42% at 50% 50%); */
        border-radius: 50%;
        /* text-align: center; */
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .donation-cards-container h1 {
        font-size: 1rem;
        color: white;
        /* justify-content: center; */
        /* padding: 1rem 0; */
        /* background-color: rebeccapurple; */
    }
}

/* make it responsive for ipads */
@media screen and (max-width: 1024px) and (min-width: 768px) {
   
    .donation-cards-container {
        height: 200px;
        width: 200px;
        background-color: blueviolet;
        /* border-radius: 50%; */
        align-items: center;
        justify-content: space-evenly;
        display: flex;

    }

    .donation-cards-container h1 {
        font-size: 1rem;
        color: white;
        /* justify-content: center; */
        /* padding: 1rem 0; */
        /* background-color: rebeccapurple; */
    }
}

@media screen and (max-width: 450px) {

    .donation-cards-container {
        height: 90px;
        width: 90px;
        /* background-color: blueviolet; */
        /* clip-path: ellipse(25% 42% at 50% 50%); */
        /* border-radius: 100%; */
        /* text-align: center; */
        align-items: center;
        justify-content: center;
        display: flex;
    }

    .donation-cards-container h1 {
        font-size: 1rem;
        color: white;
        /* justify-content: center; */
        /* padding: 1rem 0; */
        /* background-color: rebeccapurple; */
    }
}