

.our-work-container {
    background-color: #f5f5f5;
    width: 80%;
    margin: 0 auto;
    padding: 5% 0%;
    margin-top: -4%;
    overflow: hidden;
}


.our-work-container h1 {
    font-size: 2rem;
    text-align: center;
    /* padding: 2rem 0; */
    justify-content: center;
}
.our-work-container p {
    /* background-color: aliceblue; */
    /* height: 100vh; */
    width: 100%;
    display: flex;
    /* flex-direction: row; */
    /* align-items: center; */
    /* justify-content: space-around; */
    gap: 2rem;
    padding: 0 20%;
    font-size: 1.2rem;

}

.donate-button {
    /* remove link underline */
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}


/* make it responsive for mobile phones */
@media screen and (max-width: 360px) {
    .our-work-container {
        background-color: #f5f5f5;
        width: 100%;
        margin: 0 auto;
        /* padding: 5% 0%; */
        margin-top: -4%;
        overflow: hidden;
    }
    .our-work-container h1 {
        font-size: 1.5rem;
        /* text-align: center; */
        /* padding: 2rem 0; */
        /* justify-content: left; */
        display: flex;
        padding-left: 10vw;
        float: left;
    }
    .our-work-container p {
        width: 100%;
        display: flex;
        gap: 2rem;
        /* padding: 0 20%; */
        padding-left: 10vw;
        font-size: 1.2rem;
        text-align: left;
        align-items: left;

    }
    .donate-button {
        /* remove link underline */
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}