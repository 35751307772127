

/* for lage and medium screens */
@media screen and (min-width: 768px) {
    .footer-container {
        background-color: #9c27b0;
        height: 50vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 10%;
        position: relative;
        width: 100%;
        bottom: 0;
        
    }
    .footer-container h1 {
        font-size: 1.2rem;
        color: white;
    }
}

.footer-container {
    /* background-color: aliceblue;
    height: 50%;
    width: 100%;
    display: flex; */
    /* flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%; */

}

/* for mobile phones */
@media screen and (max-width: 768px) {
    .footer-container {
        background-color: #9c27b0;
        /* height: 50vh; */
        height: 100%;
        text-align: center;
        position: relative;
        width: 100%;
        bottom: 0;
        padding-bottom: 2vh;
    }
    .footer-container h1 {
        font-size: 1.2rem;
        color: white;
    }

    .social-container {
        padding-top: 1rem;
    }
    .social-icons {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        justify-content: center;
    }
    .contact-container {
        padding-top: 2rem;
        /* text-align: left; */
        /* padding-left: 4rem; */

    }
}