


/* .contact-form-container:not(button){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 1rem;
} */

.contact-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 1rem;
}

.contact-form-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 1rem;
    padding-bottom: 2rem;
}

.contact-form-filed {
    width: 30vw;
}

/* make it responsive for mobile phones */
@media screen and (max-width: 360px) {
    .contact-form-container {
        /* background-color: #f5f5f5; */
        width: 100%;
        margin: 0 auto;
        /* padding: 5% 0%; */
        margin-top: -4%;
        overflow: hidden;
    }
    .contact-form-container form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding-bottom: 2rem;
    }
    .contact-form-filed {
        width: 80vw;
    }
}

/* make it responsive for mobile phones */
@media screen and (max-height: 600px) {
    .contact-form-container {
        /* background-color: #f5f5f5; */
        width: 100%;
        margin: 0 auto;
        /* padding: 5% 0%; */
        margin-top: -4%;
        overflow: hidden;
    }
    .contact-form-container form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding-bottom: 2rem;
    }
    .contact-form-filed {
        width: 50vw;
    }
}