


.contact-page-container {
    /* background-color: aliceblue; */
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    /* justify-content: space-around; */
}

.contact-page-container h1 {
    font-size: 2rem;
    text-align: center;
    /* padding: 2rem 0; */
    justify-content: center;
}

.contact-page-container p {
    width: 100%;
    /* justify-content: center; */
    text-align: center;
    gap: 2rem;
    padding: 0 20%;
    font-size: large;
}

/* make it responsive for mobile phones */
@media screen and (max-width: 360px) {
    .contact-page-container {
        /* background-color: #f5f5f5; */
        width: 100%;
        margin: 0 auto;
        /* padding: 5% 0%; */
        margin-top: -4%;
        overflow: hidden;
    }
    .contact-page-container h1 {
        font-size: 1.5rem;
        text-align: left;
        /* padding: 2rem 0; */
        /* justify-content: left; */
        /* display: flex; */
        /* padding-left: 10vw; */
        float: left;
    }
    .contact-page-container p {
        width: 100%;
        display: flex;
        gap: 2rem;
        /* padding: 0 20%; */
        text-align: left;
        /* align-items: center; */
        /* justify-content: center; */
    }
}

/* make it responsive for horizontal mobiles */
@media screen and (max-height: 600px) {
    .contact-page-container {
        /* background-color: black; */
        width: 100%;
        height: 150vh;
        margin: 0 auto;
        /* padding: 5% 0%; */
        margin-top: -4%;
        overflow: hidden;
    }
    .contact-page-container h1 {
        font-size: 1.5rem;
        text-align: left;
        /* padding: 2rem 0; */
        /* justify-content: left; */
        /* display: flex; */
        /* padding-left: 10vw; */
        float: left;
    }
    .contact-page-container p {
        width: 100%;
        display: flex;
        gap: 2rem;
        /* padding: 0 20%; */
        text-align: left;
        /* align-items: center; */
        /* justify-content: center; */
    }
}