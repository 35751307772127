


.partner-page-container {
    background-color: aliceblue;
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    /* justify-content: space-around; */
}

.partner-page-container h1 {
    font-size: 2rem;
    text-align: center;
    /* padding: 2rem 0; */
    justify-content: center;
}

.partner-page-container p {
    width: 100%;
    /* justify-content: center; */
    text-align: center;
    gap: 2rem;
    padding: 0 20%;
    font-size: large;
}