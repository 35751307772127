


.history-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-left: 25vw;
    padding-right: 25vw;
    background-color: aliceblue;
    gap: 2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.history-image img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 50%;
}

.history-image-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    text-align: left;
    font-size: 1.2rem;
}

.history-link {
    /* remove link underline */
    /* text-decoration: none; */
}

/* make it responsive for mobile phones */
@media screen and (max-width: 768px) {
    .history-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 5vw;
        padding-right: 5vw;
        padding-top: 5vh;
    }
    .history-image img {
        width: 200px;
        height: 200px;
    }
    .history-image-text {
        /* padding-top: -2vh; */
        text-align: center;
    }
}