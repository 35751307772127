


.people-container {
    background-color: antiquewhite;
    /* height: auto; */
    

}

.poeple-container-header {
    text-align: center;
    font-size: 1.8rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.people {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
    padding-left: 5%;
    padding-right: 5%;
}

.person h1 {
    font-size: 1.5rem;
    text-align: center;
    margin: auto;
}

.person p {
    font-size: 1rem;
    text-align: center;
    margin: auto;
    padding-bottom: 1rem;
}


.person img {
    height: 200px;
    width: 200px;
    /* aspect-ratio: 1/1; */
    object-fit: cover;
    border-radius: 50%;
}

/* make it responisve for mobile ohones and small screens */
@media screen and (max-width: 768px) {
    .people-container {
        background-color: antiquewhite;
        height: 100%;
        text-align: center;
    }
    .people {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .person {
        margin: 10px;
    }
}